import React, { useEffect, useState } from "react";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
// import Projects from "./components/Projects";
import Skills from "./components/Skills";

const App = () => {
  const [sharedData, setSharedData] = useState({});
  const [resumeData, setResumeData] = useState({});

  const loadSharedData = async () => {
    try {
      const data = await fetch(`portfolio_shared_data.json`);
      if (!data.ok) {
        throw new Error(`HTTP error! status: ${data.status}`);
      }
      const sharedData = await data.json();
      setSharedData(sharedData);
      document.title = `${sharedData.basic_info.name}`;
    } catch (error) {
      //TODO sentry
      console.log(error);
    }
  };

  const loadResume = async () => {
    try {
      const data = await fetch(`res_primaryLanguage.json`);
      if (!data.ok) {
        throw new Error(`HTTP error! status: ${data.status}`);
      }
      const resumeData = await data.json();
      setResumeData(resumeData);
    } catch (error) {
      //TODO sentry
      console.log(error);
    }
  };

  useEffect(() => {
    loadSharedData();
    loadResume();
  }, []);

  return (
    <div>
      <Header sharedData={sharedData.basic_info} />

      <About
        resumeBasicInfo={resumeData.basic_info}
        sharedBasicInfo={sharedData.basic_info}
      />
      {/* <Projects
        resumeProjects={resumeData.projects}
        resumeBasicInfo={resumeData.basic_info}
      /> */}
      <Skills
        sharedSkills={sharedData.skills}
        resumeBasicInfo={resumeData.basic_info}
      />
      <Experience
        resumeExperience={resumeData.experience}
        resumeBasicInfo={resumeData.basic_info}
      />
      <Footer sharedBasicInfo={sharedData.basic_info} />
    </div>
  );
};

export default App;
